import React, { Component } from "react";
import Reveal from "../../common/Reveal";
import styles from "./OurServices.module.scss";

class OurServices extends Component {
  render() {
    return (
      <div className={styles.base}>
        <Reveal className={styles.content}>
          <h2>Our Services</h2>
          <Reveal className={styles.columns}>
            <div>
              <h3>Political Consulting</h3>
              <p>
                Neale Creek guides all activities and strategy surrounding the
                creation and operation of independent expenditures supporting
                political and issue advocacy campaigns. Services include
                budgeting, sourcing prospective donors, managing existing donor
                relations, fundraising and developing advertising vision. This
                includes managing the support team consisting of web vendor,
                finance, political and media consultants and legal team.
              </p>
            </div>
            <div>
              <h3>Advocacy</h3>
              <p>
                At Neale Creek, we help clients focus on targeted,
                results-oriented advocacy to advance policy and legislative
                goals. From developing high level strategies to every day
                blocking and tackling on Capitol Hill, Neale Creek helps clients
                successfully navigate some of the toughest challenges in today’s
                political and policy landscape.
              </p>
            </div>
          </Reveal>
        </Reveal>
      </div>
    );
  }
}

export default OurServices;
