import React, { Component } from "react";
import cx from "classnames";

import Reveal from "../../common/Reveal";
import Portrait from "images/King_Full_Color.png";
import MobilePortrait from "images/King_Thumbnail_Color.png";
import styles from "./AboutUs.module.scss";

class AboutUs extends Component {
  render() {
    return (
      <Reveal className={styles.base}>
        <div
          className={cx(styles.tile, styles.left)}
          style={{
            backgroundImage: `url(${Portrait})`
          }}
        ></div>
        <div className={cx(styles.tile, styles.right)}>
          <h2>About Us</h2>
          <img
            className={styles.mobilePortrait}
            src={MobilePortrait}
            alt="King"
          />
          <p>
            Neale Creek’s founder, Andrew King served for nearly two decades as
            senior staff to Senator Lindsey Graham. In this role, he was
            responsible for overseeing all appropriations issues, with an
            emphasis on defense, energy and water and the 150 Account, to
            include Africa, Mideast and State/USAID funding.
          </p>
          <p>
            Andrew left Graham’s office in 2015 to manage the independent
            expenditure campaign supporting Senator Graham’s run for president
            of the United States. Since that time, Andrew continues to manage
            campaign strategies dedicated to political and issue advocacy
            campaigns- primarily engaged on issues relating to strong national
            security and foreign policy by seeking to support candidates who
            embrace those ideals. Andrew also specializes in other issues such
            as climate change, international affairs, economic policy and
            national defense.
          </p>
          <p>
            Andrew manages government affairs and develops new business for
            North American Rescue, a defense and medical contractor dedicated to
            decreasing preventable death by providing medical products to
            governments, law enforcement and EMS. Andrew also gained additional
            experience in government relations as part of the team at the Glover
            Park Group.
          </p>
          <p>
           Andrew is also co-founder and treasurer of <a href="https://150pac.org/" target="_blank" rel="noopener noreferrer">150pac.org</a>, a bipartisan PAC 
           focused on federal candidates supportive of international affairs. 
           Andrew currently serves on the Board of Directors of No Greater Sacrifice, 
           an organization dedicated to honoring service members by investing in 
           their children through education. 
          </p>
        </div>
      </Reveal>
    );
  }
}

export default AboutUs;
