import React, { Component } from "react";
import cx from "classnames";
import Logo from "images/nealecreek_logo.png";
import styles from "./Header.module.scss";

export default class Header extends Component {
  state = { traveling: false };

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    const traveling = window.scrollY > 100;
    if (this.state.traveling !== traveling) {
      this.setState({ traveling });
    }
  };

  render() {
    return (
      <header
        className={cx(styles.base, {
          [styles.traveling]: this.state.traveling
        })}
      >
        <img src={Logo} alt="Neale Creek" />
      </header>
    );
  }
}
