import React, { Component } from "react";
import styles from "./ContactUs.module.scss";

class ContactUs extends Component {
  render() {
    return (
      <div className={styles.base}>
        <div className={styles.content}>
          <h2>Contact Us</h2>
          <div className={styles.contactLinks}>
            <a href="mailto:Andrew@NealeCreek.com">Andrew@NealeCreek.com</a>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactUs;
