import React from 'react';
import ReactDOM from 'react-dom';
import Application from './components/layout/Application';
import * as serviceWorker from './serviceWorker';
import store, { history } from './util/store';
import { ConnectedRouter } from 'react-router-redux';
import { Provider } from 'react-redux';

ReactDOM.render(
  <Provider store={ store }>
    <ConnectedRouter history={ history }>
      <Application />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

// TODO: Hot module reloading
// if (module.hot && process.env.NODE_ENV !== 'production') {
//   module.hot.accept('./components/layout/Application', () => {
//     render(
//       <Provider store={store}>
//         <ConnectedRouter history={history}>
//           <OverlayProvider>
//             <Route component={Application}/>
//           </OverlayProvider>
//         </ConnectedRouter>
//       </Provider>,
//       document.getElementById('root')
//     );
//   });
// }