import React, { Component } from 'react';
import styles from './ErrorBoundary.module.scss';

class ErrorBoundary extends Component {

  state = {
    hasError: false
  };

  componentDidCatch(error, info) {

    // Display fallback UI
    this.setState({ hasError: true });

  }

  goHome = () => {

    window.location = 'https://';

  };

  render() {

    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className={ styles.base }>
          Something went wrong.  Please return to the homepage.
        </div>
      );

    } else {

      return this.props.children;

    }

  }

}


export default ErrorBoundary;
