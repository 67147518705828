import React, { Component } from 'react';
import styles from './Footer.module.scss';

export default class Header extends Component {

  static propTypes = {
  };

  render() {

    return (
      <footer className={ styles.base }></footer>
    );

  }
}
