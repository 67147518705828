import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import thunk from 'redux-thunk';
import createHistory from 'history/createBrowserHistory';
import throttle from 'lodash/throttle';
import rootReducer from '../redux';
import { saveState, loadState } from '../redux/util/persistence';

export const history = createHistory();

const persistedState = loadState();
const initialState = {
  ...persistedState
};

// No enhancers yet..
const enhancers = [];
const middleware = [thunk, routerMiddleware(history)];

const composedEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  rootReducer,
  initialState,
  composedEnhancers(applyMiddleware(...middleware), ...enhancers)
);

// TODO: get hot module reloading working
// if (module.hot && process.env.NODE_ENV !== 'production') {
//   module.hot.accept('../redux', () => {
//     const nextRootReducer = require('../redux').default;
//     store.replaceReducer(nextRootReducer);
//   });
// }

store.subscribe(
  throttle(() => {
    saveState({
      // TODO: Add saved Tags
    })
  }),
  1000
);

export default store;
