import React, { Component } from "react";

import Logo from "images/nealecreek_logo.png";
import styles from "./MissionStatement.module.scss";
import Reveal from "../../common/Reveal";

class MissionStatement extends Component {
  render() {
    return (
      <div className={styles.base}>
        <Reveal className={styles.content}>
          <img src={Logo} alt="Neale Creek" />
          <p>
            Neale Creek, LLC is a government relations and political consulting
            firm specializing in delivering successful results to complex
            challenges. With decades of experience on the Hill and supporting
            campaigns through independent expenditures, Neale Creek is able to
            provide a wide array of services to its clients.
          </p>
        </Reveal>
      </div>
    );
  }
}

export default MissionStatement;
