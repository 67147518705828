/*

  Will wrap children in a component of your choice.
  You can provide tag type, id and classNames for the wrapper.
  Defaults to div.reveal

  Wrapper will also get a custom className based on the 'name' prop.
  The default is 'fade-in', which creates div.reveal-fade-in.

  You can create styles/transitions/animations that apply to each
  unique name. For example, see /assets/stylesheets/components/_reveal.scss

  Whenever the wrapper enters the viewport, the wrapper will receive an
  additional className of "visible". Write animations accordingly.

  Use the `offset` prop to determine how much of teh element needs to be
  visible to trigger the .visible className. Eg. "25%"

  Uses react-waypoint. See https://github.com/brigade/react-waypoint for insights.

  Note: You can provide an array of children, and each will receive an additional
  in-line style rule of `transitionDelay: n * 111ms`, where 111 is the default value of
  this.props.stagger. All children will be put into a single wrapper, so they will
  all trigger at the same time, but the transition delay will create a cascading effect.

*/

import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Waypoint from "react-waypoint";
import _isArray from "lodash/isArray";

import "./reveal.scss";

export default class Reveal extends Component {
  static propTypes = {
    component: PropTypes.string,
    id: PropTypes.string,
    className: PropTypes.string,
    name: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
    offset: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    stagger: PropTypes.number
  };

  static defaultProps = {
    component: "div",
    name: "fade-in",
    stagger: 333
  };

  state = { visible: false };

  setVisibile = () => {
    this.setState({ visible: true });
  };

  setInvisibile = () => {
    this.setState({ visible: false });
  };

  exit = wp => {
    // console.log( 'exit', wp, Waypoint.inside )
    if (wp.currentPosition === "below" && wp.previousPosition === "inside") {
      this.setInvisibile();
    }
  };

  getBaseClassNames() {
    return classNames("reveal", this.props.className, {
      [`reveal-${this.props.name}`]: this.props.name
    });
  }

  getRevealClassNames() {
    return classNames(this.getBaseClassNames(), {
      visible: this.state.visible
    });
  }

  renderOffsetChild = (child, i) => {
    return React.cloneElement(child, {
      key: child.key || `reveal-child-${i}`,
      style: {
        ...child.props.style,
        transitionDelay: `${Math.sqrt(i) * 0.5 - 0.5}s`
      }
    });
  };

  getChildren() {
    if (_isArray(this.props.children)) {
      return this.props.children.map(this.renderOffsetChild);
    } else {
      return this.props.children;
    }
  }

  render() {
    return (
      <Waypoint
        scrollableAncestor="window"
        onEnter={this.setVisibile}
        onLeave={this.exit}
        topOffset={this.props.offset || "25%"}
        bottomOffset={this.props.offset || "25%"}
      >
        <this.props.component
          id={this.props.id || null}
          className={this.getRevealClassNames()}
        >
          {this.getChildren()}
        </this.props.component>
      </Waypoint>
    );
  }
}
