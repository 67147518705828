import React, { Component } from "react";
import "../../../stylesheets/main.scss";
import styles from "./Application.module.scss";
import { Route } from "react-router-dom";
import ErrorBoundary from "../../common/ErrorBoundary";
import TestErrorBoundary from "../../common/ErrorBoundary/TestErrorBoundary";
import Header from "../shell/Header";
import Footer from "../shell/Footer";
import MissionStatement from "../../content/MissionStatement";
import OurServices from "../../content/OurServices";
import AboutUs from "../../content/AboutUs";
import ContactUs from "../../content/ContactUs";

class Application extends Component {
  render() {
    return (
      <ErrorBoundary>
        <div className={styles.base}>
          <Header />
          <Route path="/test-error-boundary" component={TestErrorBoundary} />
          <MissionStatement />
          <OurServices />
          <AboutUs />
          <ContactUs />
          <Footer />
        </div>
      </ErrorBoundary>
    );
  }
}

export default Application;
