import { Component } from 'react';

class TestErrorBoundry extends Component {

  componentDidMount() {

    throw new Error('Error Boundry Test');

  }

  render() {

    return null;

  }

}

export default TestErrorBoundry;